var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"events",attrs:{"id":"events"}},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"event"},[_vm._m(1),_c('div',{staticClass:"event-content"},[_c('div',{staticClass:"event-title"},[_c('router-link',{attrs:{"to":"/event-details","target":"_blank"}},[_c('h4',[_vm._v("Yoga For Healthy Living")])])],1),_vm._m(2),_vm._m(3),_c('router-link',{staticClass:"event-more",attrs:{"to":"/event-details","target":"_blank"}},[_vm._v(" Continue Reading ")]),_vm._m(4)],1)])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"event"},[_vm._m(5),_c('div',{staticClass:"event-content"},[_c('div',{staticClass:"event-title"},[_c('router-link',{attrs:{"to":"/event-details","target":"_blank"}},[_c('h4',[_vm._v("Hatha Yoga Training Festival")])])],1),_vm._m(6),_vm._m(7),_c('router-link',{staticClass:"event-more",attrs:{"to":"/event-details","target":"_blank"}},[_vm._v(" Continue Reading ")]),_vm._m(8)],1)])])]),_c('div',{staticClass:"my-btn text-center"},[_c('router-link',{staticClass:"main-btn",attrs:{"to":"/events","target":"_blank"}},[_c('span',[_vm._v("All Events")])])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-title text-center"},[_c('span',{staticClass:"separator"},[_c('i',{staticClass:"flaticon-chakra"})]),_c('h2',[_vm._v("Upcoming Events")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"event-img"},[_c('img',{attrs:{"src":"https://via.placeholder.com/800x560","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"event-info list-unstyled"},[_c('li',{staticClass:"time"},[_c('i',{staticClass:"flaticon-clock-circular-outline"}),_vm._v("8:00 am 3:00 pm ")]),_c('li',[_c('i',{staticClass:"flaticon-placeholder"}),_vm._v("New York City")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"event-text"},[_c('p',[_vm._v(" Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et... ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"event-date"},[_c('span',[_vm._v("18")]),_vm._v(" Feb")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"event-img"},[_c('img',{attrs:{"src":"https://via.placeholder.com/800x560","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"event-info list-unstyled"},[_c('li',{staticClass:"time"},[_c('i',{staticClass:"flaticon-clock-circular-outline"}),_vm._v("8:00 am 3:00 pm ")]),_c('li',[_c('i',{staticClass:"flaticon-placeholder"}),_vm._v("New York City")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"event-text"},[_c('p',[_vm._v(" Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et... ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"event-date"},[_c('span',[_vm._v("20")]),_vm._v(" Feb")])
}]

export { render, staticRenderFns }